(function () {
  'use strict';

  angular
      .module('neo.home.championships.championship')
      .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('home.championships.championship', {
          url: '/{championshipId}',
          templateUrl: 'home/championships/championship/championship.tpl.html',
          controller: 'ChampionshipCtrl',
          controllerAs: 'vm',
          resolve: {
            championship: function (Championship, $stateParams) {
              return Championship.query({id: $stateParams.championshipId}).$promise;
            }
          }
        });
  }
}());
